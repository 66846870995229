* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
