.cart-parent {
  margin-top: 7rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-inline: 3rem;
}

li {
  padding: 3rem;
  list-style: none;
}

li > * {
  margin-top: 1rem;
}

.product-card img {
  width: 200px;
  height: 200px;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  background-size: cover;
  /* border: 3px solid red; */
}

.del-btn {
  color: black;
  background: white;
  width: 8rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: bold;
  letter-spacing: 2px;
  border-radius: 0.5rem;
  transition: all 0.4s ease-in-out;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(5px);
  }
}

.del-btn:hover {
  color: white;
  background: black;
  border: 1px solid white;
  animation: shake 0.3s ease-in-out 4;
}

.total-price {
  height: 6rem;
  display: flex;
  gap: 2rem;
  justify-content: space-around;
  /* border: 3px solid red; */
  /* background: red; */
}
